import { paletteV2 } from '../../theme/palette';

/**
 * @description a flattened list of variants as array
 */
export const ALL_COLOR_VARIANTS = [
  'cherry-400',
  'grey-0',
  'grey-20',
  'grey-25',
  'grey-75',
  'grey-200',
  'grey-300',
  'grey-400',
  'grey-700',
  'grey-1000',
  'lime-400',
  'orange-400',
  'plum-400',
  'plum-600',
  'mint-300',
] as const;

/**
 * @description a flattened list of variants
 * @note this is a subset of the palette object
 */
export type AllColorVariants = (typeof ALL_COLOR_VARIANTS)[number];

export default paletteV2;
