import {
  configureStore,
  AnyAction,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import cuesSlice from './CuesSlice';
import editorSlice from './EditorSlice';
import replacementsSlice from './ReplacementsSlice';
import drivesSlice from './DrivesSlice';
import listenerMiddleware from './listenerMiddleware';

/**
 * Redux Store ------------------------------------------
 * The store is used to manage the state of the studio editor and all things related to it.
 *
 * We use the Redux Toolkit to create the store and manage the state. This gives us the ability
 * to create slices that are responsible for managing a specific part of state, while also
 * giving us the flexibility to select across slices to create a more complex state.
 * @see https://redux-toolkit.js.org/
 *
 * Middlewares ------------------------------------------
 * We use async thunks to handle side-effects of state changes, e.g. calling the core editor
 *  commands or helpers that should occur as state changes.
 * @see https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * We use the listenermiddleware to listen for changes to the state. This can be used in components
 * that need to look for a specific action in order to react to it.
 * @see https://redux-toolkit.js.org/api/createListenerMiddleware
 *
 */
export const makeStore = () =>
  configureStore({
    reducer: {
      [cuesSlice.name]: cuesSlice.reducer,
      [editorSlice.name]: editorSlice.reducer,
      [replacementsSlice.name]: replacementsSlice.reducer,
      [drivesSlice.name]: drivesSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: true }).prepend(
        listenerMiddleware.middleware
      ),
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk = ThunkAction<void, AppState, unknown, AnyAction>;
export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>;

export const store = makeStore();
