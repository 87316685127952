import { DriveItem } from '../components/Drives/types';

export const getValidatedItems = (
  currentArr: Array<DriveItem>,
  updatedArr: Array<DriveItem>,
  selected: boolean
) => {
  if (!selected) {
    return currentArr.filter(a => !updatedArr.find(i => a.id === i.id));
  }
  return currentArr
    .filter(a => !updatedArr.find(i => a.id === i.id))
    .concat(updatedArr);
};

export default {
  getValidatedItems,
};
