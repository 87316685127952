import { Box, ButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import Icon, { IconProps } from '../Icon';
import TextButton from './TextButton';
import { paletteBridge, paletteV2 } from '../../theme/palette';

type IconTextButtonVariants = 'uppercase' | 'capitalize' | 'none';
type IconTextButtonColor = 'primary' | 'secondary';

export interface IconTextButtonProps extends ButtonProps {
  iconType: IconProps['type'];
  buttonType?: IconTextButtonVariants;
  includeBackgroundColor?: boolean;
  color?: IconTextButtonColor;
  includeIconAsPrefix?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.spacing(1.5),
    lineHeight: `16px`,
    '&.Mui-disabled, &.Mui-disabled:hover': {
      opacity: 0.5,
    },
  },
  'color-primary-root': {
    '&.Mui-disabled, &.Mui-disabled:hover': {
      color: theme.palette.primary.main,
    },
  },
  'color-secondary-root': {
    '&.Mui-disabled, &.Mui-disabled:hover': {
      color: paletteV2.grey[50],
    },
  },
  container: {
    padding: theme.spacing(0.375, 1),
    textAlign: 'center',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  'color-primary-container': {
    '&:hover': {
      color: paletteV2.lime[100],
    },
  },
  'color-secondary-container': {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  'color-secondary-container-include-background': {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: paletteBridge.buttons.secondary.hover,
    },
  },
  icon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  label: {
    gap: theme.spacing(0.5),
  },
}));

const IconTextButton = React.forwardRef<HTMLButtonElement, IconTextButtonProps>(
  (props, ref) => {
    const {
      children,
      iconType,
      buttonType = 'capitalize',
      className = '',
      color = 'secondary',
      includeBackgroundColor = true,
      includeIconAsPrefix = true,
      style = {},
      ...rest
    } = props;

    const classes = useStyles();

    const buttonTypeStyles = {
      textTransform: buttonType,
      fontWeight: buttonType === 'uppercase' ? 'bold' : 'normal',
      ...style,
    };

    const rootClass = `color-${color}-root` as keyof typeof classes;
    const containerClass = `color-${color}-container` as keyof typeof classes;
    const containerWithBackgroundClass =
      `color-${color}-container-include-background` as keyof typeof classes;
    const textButtonClass = clsx(
      className,
      classes.container,
      typeof color === 'string' && classes[containerClass],
      includeBackgroundColor &&
        color === 'secondary' &&
        classes[containerWithBackgroundClass]
    );

    return (
      <TextButton
        variant="text"
        ref={ref}
        color={color}
        className={textButtonClass}
        classes={{
          label: classes.label,
          root: clsx(classes.root, classes[rootClass]),
        }}
        style={buttonTypeStyles}
        {...rest}
      >
        {includeIconAsPrefix && (
          <Box height={16} width={16}>
            <Icon type={iconType} className={classes.icon} />
          </Box>
        )}
        {children}
        {!includeIconAsPrefix && (
          <Box height={16} width={16}>
            <Icon type={iconType} className={classes.icon} />
          </Box>
        )}
      </TextButton>
    );
  }
);

export default IconTextButton;
