import {
  ActorAccessLevel,
  ActorVariantTypeId,
  AudioFileFormat,
  FilterAvatarsInput,
  ProjectAccessRoleEnum,
  ProjectsOrderBy,
  ScriptFormat,
  StudioProjectVersion,
  SubscriptionInterval,
  TeamAvatarSettingsAction,
  TeamRole,
  UserAvatarSettingsAction,
} from '../../server/graphql/__generated.types';
import Editor from '../context/editor/index.d';
import { CompareSemanticVersionsResult } from '../util/semVerUtils';
import { Announcement } from '../components/Announcement';
import { VoiceTab } from '../components/Voices/contexts/tabs';
import { SignupUpgradeSlides } from '../components/Subscriptions/SubscriptionTiersPrompt/SubscriptionUpgradeModalV2/hooks/useUpgradeModalSlider';
import { StudioCueType } from '../components/StudioCues/Cue';
import { SelectionSetItemId } from '../components/Subscriptions/Onboarding/components/SelectionCard/types';

export type ProjectType = 'Personal' | 'Shared' | 'Team';

export enum PageType {
  'projects' = 'projects',
  'studio' = 'studio',
  'avatars' = 'voices',
  'phonetic' = 'libraries',
  'resources' = 'resources',
  'api' = 'api',
  'integrations' = 'integrations',
  'account' = 'account',
  'admin' = 'admin',
}

export enum EventName {
  ActorSamplePlayed = 'Actor Sample Played',
  ActorSearchInteraction = 'Actor Search Interaction',
  ActorSelected = 'Actor Selected',
  ActorSelectionPromptOpened = 'Actor Selection Prompt Opened',
  ActorVariantChange = 'Actor Variant Change',
  ActorVariantVersionChanged = 'Actor Variant Version Changed',
  AddTeamMemberCTAClicked = 'Add Team Member CTA Clicked',
  AddTeamMemberSeatsRequestComplete = 'Add Team Member Seats Request Complete',
  AddTeamSeatsRequestCTAClicked = 'Add Team Seats Request CTA Clicked',
  AnnouncementCTAClicked = 'Announcement CTA Clicked',
  AnnouncementDismiss = 'Announcement Dismiss',
  ApiPageExternalLinkClick = 'Api Page External Link Click',
  ApiKeyRequest = 'Api Key Request',
  ApiContactUs = 'API Contact Us',
  AvatarCatalogCustomVoiceCTAClicked = 'Avatar Catalog Custom Voice Link Click',
  AvatarCatalogSubmitIdeaCTAClicked = 'Avatar Catalog Submit Idea CTA Click',
  AvatarOptions = 'Avatar Options Selected',
  AvatarsFiltered = 'Avatars Filtered',
  BatchClipsDelete = 'Batch Clip Delete',
  BatchClipsRestore = 'Batch Clip Restore',
  ClearAllCues = 'Clear All Cues',
  ClientVersionMismatchDetected = 'Client Version Mismatch Detected',
  ClipCopiedProject = 'Clip Copied Project',
  ClipDeleted = 'Clip Deleted',
  ClipDownload = 'Clip Download',
  ClipMovedProject = 'Clip Moved Project',
  ClipPreview = 'Clip Preview',
  ClipPreviewPlay = 'Clip Preview Play',
  ClipPreviewDelete = 'Clip Preview Delete',
  ClipPreviewSessionCount = 'Clip Preview Session Count',
  ContinueWithUnsavedPreviews = 'Continue With Unsaved Previews',
  ClipPlay = 'Clip Play',
  ClipRealtime = 'Clip Realtime',
  ClipRenamed = 'Clip Re-named',
  ClipRender = 'Clip Render',
  ClipRenderedBatch = 'Clip Rendered Batch',
  ClipRenderingCanceled = 'Clip Rendering Canceled',
  ClipReorder = 'Clip Re-order',
  ClipReRender = 'Clip Re-render',
  ClipPreviewRepopulateCues = 'Clip Preview Repopulate Cues',
  ClipRestored = 'Clip Restored',
  ClipSearch = 'Clip Search',
  ClipSave = 'Clip Save',
  CuesDisabled = 'Cues Disabled',
  CueSliderUpdated = 'Cue Slider Updated',
  CreateFolder = 'Create Folder',
  EditorUndoPressed = 'Editor Undo Pressed',
  EditorRedoPressed = 'Editor Redo Pressed',
  EditorResized = 'Editor Resized',
  EditorModeChanged = 'Editor Mode Changed',
  FloatingPhoneticReplacementToggled = 'Floating Phonetic Replacement Toggled',
  FloatingPhoneticReplacementModified = 'Floating Phonetic Replacement Modified',
  GlobalAudioFileFormatChanged = 'Global Audio File Format Changed',
  GlobalAudioFileFormatSelectedChanged = 'Global Audio File Format Selected Changed',
  HelpClickAdditionalLanguages = 'Help Click - Additional Languages',
  HelpClickBetaProject = 'Help Click - Beta Project',
  HelpClickCues = 'Help Click - Cues',
  HelpClickEditorRespellingToggle = 'Help Click - Editor Respelling Toggle',
  HelpClickLibraryLearnMore = 'Help Click - Library Learn More',
  HelpClickLibraryReplacements = 'Help Click - Library Replacements',
  HelpClickLibraryRespellingGuide = 'Help Click - Library Respelling Guide',
  HelpClickLibraryRespellingToggle = 'Help Click - Library Respelling Toggle',
  IncludeClipScriptFormatsOptionChanged = 'Include Clip Script Formats Option Changed',
  InvitationAccepted = 'Invitation Accepted',
  InviteSent = 'Invite Sent',
  IntegrationLaunch = 'Integration Launch',
  LeftRailOpen = 'Left Rail Open',
  LeftRailClose = 'Left Rail Close',
  MakerConfirmationPrompt = 'Maker Confirmation Prompt',
  MultipleTTSVersionOptionChanged = 'Multiple TTS Version Option Changed',
  DoNotShowClipDeletionConfirmationModalChanged = 'Do Not Show Clip Deletion Confirmation Modal Changed',
  OpenPlayground = 'Open Playground',
  OnboardingModal = 'Onboarding Modal',
  DoNotShowClipPreviewDeletionConfirmationModalChanged = 'Do Not Show Clip Preview Deletion Confirmation Modal Changed',
  DoNotShowNavigationConfirmationModalChanged = 'Do Not Show Navigation Confirmation Modal Changed',
  PageView = 'Page View',
  PhoneticDeletion = 'Phonetic Replacement Deleted',
  PhoneticReplacementCreated = 'Phonetic Replacement Created',
  PhoneticReplacementMigrationPrompt = 'Phonetic Replacement Migration Prompt',
  PhoneticReplacementOptionChanged = 'Phonetic Replacement Option Changed',
  PhoneticReplacementPreviewRender = 'Phonetic Replacement Preview Render',
  PhoneticReplacementToggled = 'Phonetic Replacement Toggled',
  PhoneticTabView = 'Phonetic Tab View',
  ProjectCreated = 'Project Created',
  ProjectDownload = 'Project Download',
  ProjectLocked = 'Project Locked',
  ProjectReordered = 'Project Reordered',
  PromptShown = 'Prompt Shown',
  ProjectSearch = 'Project Search',
  ProjectShared = 'Project Shared',
  ProjectSorted = 'Project Sorted',
  ProjectUnlocked = 'Project Unlocked',
  RenderModeOptionChanged = 'Render Mode Option Changed',
  RightRailOpen = 'Right Rail Open',
  RightRailClose = 'Right Rail Close',
  ReplacementsDictionaryShown = 'Replacements Dictionary Shown',
  SelfServeTeamAdjustSeatCount = 'Self-Serve Team Adjust Seat Count',
  SelfServeTeamAbandonUpgrade = 'Self-Serve Team Abandon Upgrade',
  SubscriptionUpgradeConfirmed = 'Subscription Upgrade Confirmed',
  SubscriptionUpgradeAdvance = 'Subscription Upgrade Advance',
  SubscriptionUpgradeAbandoned = 'Subscription Upgrade Abandoned',
  SubscriptionUpgradePromptPlanSelected = 'Subscription Upgrade Prompt Plan Selected',
  SubscriptionUpgradePromptShown = 'Subscription Upgrade Prompt Shown',
  TeamRemoveMember = 'Team Remove Member',
  TutorialChipSelected = 'Tutorial Chip Selected',
  UnauthenticatedRedirect = 'Unauthenticated Redirect',
  UserUpdatedAddress = 'User Updated Address',
  UserConductPromptAccepted = 'User Conduct Prompt Accepted',
  UserConductPromptShown = 'User Conduct Prompt Shown',
  UserDismissedAddressPrompt = 'User Dismissed Address Prompt',
  UserPromptedToAddAddress = 'User Prompted To Add Address',
  ViewApiDocumentation = 'View Api Documentation',
  VoiceSearch = 'Voice Search',
  VoiceFilterSelected = 'Voice Filter Selected',
  VoiceTabSelected = 'Voice Tab Selected',
  ContentTileCTAClicked = 'Content Tile CTA Clicked',
}

type EventType<K extends EventName, V = undefined> = {
  type: K;
} & (V extends undefined ? { data?: V } : { data: V });

export type Event =
  | EventType<
      EventName.ActorSamplePlayed,
      {
        actorId: string;
        actorName: string;
        accessLevel?: ActorAccessLevel | null;
        location: 'list' | 'modal';
        variantId?: string;
      }
    >
  | EventType<
      EventName.ActorSearchInteraction,
      {
        action: 'blur' | 'focus' | 'clear';
        text?: string;
        location: 'list' | 'modal';
      }
    >
  | EventType<
      EventName.ActorSelected,
      {
        actorId: string;
        actorName: string;
        location: 'list' | 'modal';
        tab?: VoiceTab;
      }
    >
  | EventType<
      EventName.ActorSelectionPromptOpened,
      // @todo we should update all avatar selection prompts with path
      { path: string; programmatic?: boolean } | undefined
    >
  | EventType<
      EventName.ActorVariantChange,
      {
        actorId: string;
        variantId: string;
        actorName: string;
        variantTypeId: ActorVariantTypeId;
      }
    >
  | EventType<
      EventName.AvatarOptions,
      {
        avatarId: string;
        variantId?: string;
        action:
          | UserAvatarSettingsAction
          | `Team${TeamAvatarSettingsAction}`
          | 'StartProject'
          | 'SelectAvatar';
        tab?: VoiceTab;
      }
    >
  | EventType<
      EventName.AvatarCatalogSubmitIdeaCTAClicked,
      {
        projectVersion: StudioProjectVersion;
        ctaUrl: string;
      }
    >
  | EventType<
      EventName.AvatarCatalogCustomVoiceCTAClicked,
      {
        projectVersion: StudioProjectVersion;
        ctaUrl: string;
      }
    >
  | EventType<
      EventName.AddTeamMemberCTAClicked,
      {
        source: TeamAccountManagementSource;
      }
    >
  | EventType<EventName.AddTeamMemberSeatsRequestComplete>
  | EventType<
      EventName.AddTeamSeatsRequestCTAClicked,
      {
        source: TeamAccountManagementSource;
      }
    >
  | EventType<
      EventName.AnnouncementDismiss,
      {
        announcementId: string;
        message: string;
        dismissedAt: string;
        rangeIntervals: Announcement['announcementIntervals'];
      }
    >
  | EventType<
      EventName.AnnouncementCTAClicked,
      {
        announcementId: string;
        announcementCTAUrl: string;
        message: string;
      }
    >
  | EventType<
      EventName.FloatingPhoneticReplacementToggled,
      {
        text: string;
        replacement: string;
        enabled: boolean;
      }
    >
  | EventType<
      EventName.FloatingPhoneticReplacementModified,
      {
        text: string;
        previousReplacement: string;
        newReplacement: string;
      }
    >
  | EventType<
      EventName.ApiPageExternalLinkClick,
      {
        ctaText: string;
        href: string;
        source: 'page' | 'toolbar';
      }
    >
  | EventType<
      EventName.ApiKeyRequest,
      {
        status: ApiKeyRequestStatus;
      }
    >
  | EventType<
      EventName.ApiContactUs,
      {
        ctaText: string;
        href: string;
      }
    >
  | EventType<
      EventName.ViewApiDocumentation,
      {
        ctaText: string;
        href: string;
      }
    >
  | EventType<
      EventName.OpenPlayground,
      {
        ctaText: string;
        href: string;
      }
    >
  | EventType<
      EventName.ClientVersionMismatchDetected,
      {
        actionTaken: 'forceReload' | `none`;
        versionChange?: CompareSemanticVersionsResult;
        clientVersion?: string;
        serverVersion?: string;
      }
    >
  | EventType<EventName.ClipDeleted, { clipId: string; wasRecredited: boolean }>
  | EventType<
      EventName.ClipDownload,
      {
        clipIds: string[];
        audioFormat: string;
        success: boolean;
        elapsedTimeMs: number;
        clipsCount: number;
        combinedTextLength?: number;
        numberOfClipsJustConverted?: number;
        zipFileAlreadyExisted?: boolean;
        actorId?: number;
        actorVariantId?: number;
        actorVariantType?: string;
        actorName?: string;
        ttsVersion?: string;
        error?: string;
        includeScriptFormats?: ScriptFormat[];
      }
    >
  | EventType<
      EventName.ProjectDownload,
      {
        projectId: string;
        audioFormat: string;
        success: boolean;
        elapsedTimeMs: number;
        combinedTextLength?: number;
        clipsCount?: number;
        numberOfClipsJustConverted?: number;
        zipFileAlreadyExisted?: boolean;
        error?: string;
        includeScriptFormats?: ScriptFormat[];
      }
    >
  | EventType<
      EventName.ClipRealtime,
      {
        clipId: string;
        previousProjectId?: string;
        projectId: string;
        actorId: string;
        actorVariantId: string;
        actorVariantType: string;
        actorName: string;
        eventType: ClipEventRealtime;
        textLength: number;
        ttsVersion: string;
      }
    >
  | EventType<EventName.ClipRender, BaseClipRenderEvent>
  | EventType<EventName.ClipPreview, BaseClipRenderEvent>
  | EventType<EventName.ClipPreviewPlay, { actorId: string; clipId: string }>
  | EventType<EventName.ClipPreviewDelete>
  | EventType<
      EventName.ClipPreviewSessionCount,
      { total: number; saved: number }
    >
  | EventType<EventName.ContinueWithUnsavedPreviews>
  | EventType<EventName.ClipPlay, { actorId: string; clipId: string }>
  | EventType<
      EventName.ClipRenderedBatch,
      {
        actorId: string;
        actorVariantId: string;
        actorVariantType: string;
        actorName: string;
        totalClips: number;
        totalLength: number;
        minLength: number;
        maxLength: number;
        meanLength: number;
        renderMode: Editor.StoreState['renderMode'];
      }
    >
  | EventType<
      EventName.ClipReRender,
      {
        clipId: string;
        actorId: string;
        actorVariantId: string;
        actorVariantType: string;
        actorName: string;
        textLength: number;
        isCombined: boolean;
        ttsVersion: string;
      }
    >
  | EventType<
      EventName.ClipPreviewRepopulateCues,
      {
        clipId: string;
        actorId: string;
        actorVariantId: string;
        actorVariantType: string;
        actorName: string;
        textLength: number;
        ttsVersion: string;
      }
    >
  | EventType<EventName.ClipRestored, { clipId: string }>
  | EventType<EventName.ClipSearch, { projectId: string }>
  | EventType<
      EventName.CuesDisabled,
      {
        action: 'tabClick';
      }
    >
  | EventType<
      EventName.BatchClipsDelete,
      { total: number; numRecredited: number }
    >
  | EventType<EventName.BatchClipsRestore, { total: number }>
  | EventType<EventName.EditorResized, { projectId: string; height: number }>
  | EventType<
      EventName.EditorModeChanged,
      { projectId: string; editMode: string }
    >
  | EventType<EventName.InviteSent, { role: string; selfServe: boolean }>
  | EventType<
      EventName.IntegrationLaunch,
      { type: 'canva' | 'adobe'; app?: 'canva' | 'express' | 'premiere' }
    >
  | EventType<EventName.LeftRailOpen, { page: PageType }>
  | EventType<EventName.LeftRailClose, { page: PageType }>
  | EventType<EventName.InvitationAccepted, { role: string }>
  | EventType<EventName.PageView, { page: string }>
  | EventType<
      EventName.ProjectCreated,
      { withClientName: boolean; studioVersion?: StudioProjectVersion }
    >
  | EventType<EventName.ProjectLocked>
  | EventType<EventName.ProjectUnlocked>
  | EventType<
      EventName.PhoneticReplacementCreated,
      {
        path: string;
        type: 'personal' | 'team';
      }
    >
  | EventType<
      EventName.PhoneticReplacementPreviewRender,
      {
        path: string;
        // these fields are optional as they are only available in project detail
        avatarId?: number;
        avatarVariantId?: number;
        version?: string;
        location?: string;
      }
    >
  | EventType<EventName.PromptShown, { prompt: string }>
  | EventType<EventName.PhoneticReplacementOptionChanged, { value: boolean }>
  | EventType<
      EventName.PhoneticReplacementMigrationPrompt,
      {
        projectId: string;
        action: 'start' | 'complete';
        totalReplacements: number;
      }
    >
  | EventType<
      EventName.PhoneticTabView,
      { libraryType: string; location: string }
    >
  | EventType<
      EventName.PhoneticReplacementToggled,
      {
        tab: string;
        type: 'all' | 'single';
        enabled: boolean;
      }
    >
  | EventType<EventName.PhoneticDeletion, { type: 'word' | 'replacement' }>
  | EventType<
      EventName.RenderModeOptionChanged,
      { value: Editor.StoreState['renderMode'] }
    >
  | EventType<EventName.RightRailOpen, { page: PageType }>
  | EventType<EventName.RightRailClose, { page: PageType }>
  | EventType<
      EventName.SubscriptionUpgradePromptShown,
      {
        upgradeContext: string;
        upgradePath: string;
      }
    >
  | EventType<
      EventName.SubscriptionUpgradePromptPlanSelected,
      {
        name: string;
        upgradePath: string;
      }
    >
  | EventType<
      EventName.SubscriptionUpgradeConfirmed,
      {
        addressCountry?: string;
        taxIdType?: string;
        fromPlan?: {
          id: string;
          name: string;
          interval: string;
        } | null;
        toPlan?: {
          id: string;
          name: string;
          interval: string;
        };
        seatCount?: number;
        name: string;
        upgradePath: string;
        success: boolean;
      }
    >
  | EventType<
      EventName.SubscriptionUpgradeAdvance,
      {
        addressCountry?: string;
        taxIdType?: string;
        fromPlan?: {
          id: string;
          name: string;
          interval: string;
        } | null;
        toPlan?: {
          id: string;
          name: string;
          interval: string;
        };
        name: string;
      }
    >
  | EventType<
      EventName.UserDismissedAddressPrompt,
      {
        promptSource?: string;
      }
    >
  | EventType<
      EventName.UserPromptedToAddAddress,
      {
        promptSource?: string;
      }
    >
  | EventType<
      EventName.UserUpdatedAddress,
      {
        promptSource?: string;
        prompted: boolean;
        action: 'add' | 'update';
        addressCountry?: string;
        taxIdType?: string;
      }
    >
  | EventType<
      EventName.SubscriptionUpgradeAbandoned,
      {
        fromPlan?: {
          id: string;
          name: string;
          interval: string;
        } | null;
        toPlan?: {
          id: string;
          name: string;
          interval: string;
        };
        slide: SignupUpgradeSlides;
      }
    >
  | EventType<
      EventName.UnauthenticatedRedirect,
      { path: string; detectionLocation: string }
    >
  | EventType<
      EventName.MultipleTTSVersionOptionChanged,
      {
        value: boolean;
      }
    >
  | EventType<
      EventName.DoNotShowClipDeletionConfirmationModalChanged,
      {
        value: boolean;
      }
    >
  | EventType<
      EventName.DoNotShowClipPreviewDeletionConfirmationModalChanged,
      {
        value: boolean;
      }
    >
  | EventType<
      EventName.DoNotShowNavigationConfirmationModalChanged,
      {
        value: boolean;
      }
    >
  | EventType<
      EventName.GlobalAudioFileFormatSelectedChanged,
      {
        value: boolean;
      }
    >
  | EventType<
      EventName.GlobalAudioFileFormatChanged,
      {
        value: AudioFileFormat;
      }
    >
  | EventType<
      EventName.IncludeClipScriptFormatsOptionChanged,
      {
        value: ScriptFormat[];
      }
    >
  | EventType<
      EventName.ActorVariantVersionChanged,
      {
        actorId: string;
        variantId: string;
        actorName: string;
        variantTypeId: ActorVariantTypeId;
        versionLabel: string;
        version: string;
      }
    >
  | EventType<
      EventName.MakerConfirmationPrompt,
      {
        interval: SubscriptionInterval;
        action: 'cancel' | 'confirm';
      }
    >
  | EventType<EventName.UserConductPromptShown>
  | EventType<EventName.UserConductPromptAccepted>
  | EventType<
      EventName.ProjectShared,
      | {
          type: ProjectSharedEventType.Anyone;
          projectId: string;
          access?: ProjectAccessRoleEnum;
        }
      | {
          type: ProjectSharedEventType.TeamMember;
          projectId: string;
          teamId: string;
          teamMemberIds: string[];
          access?: ProjectAccessRoleEnum;
        }
    >
  | EventType<EventName.ClipRenderingCanceled>
  | EventType<
      EventName.ProjectSorted,
      {
        type: ProjectsOrderBy;
        projectType: ProjectType;
      }
    >
  | EventType<
      EventName.ProjectSearch,
      {
        search: string;
        projectType: ProjectType;
      }
    >
  | EventType<
      EventName.ProjectReordered,
      {
        projectType: ProjectType;
      }
    >
  | EventType<
      EventName.AvatarsFiltered,
      {
        filters: FilterAvatarsInput[];
      }
    >
  | EventType<
      EventName.ClipCopiedProject,
      {
        clipsCount: number;
      }
    >
  | EventType<EventName.HelpClickAdditionalLanguages, { action: 'linkClick' }>
  | EventType<EventName.HelpClickBetaProject, { action: 'linkClick' }>
  | EventType<EventName.HelpClickCues, { action: 'linkClick' }>
  | EventType<
      EventName.HelpClickEditorRespellingToggle,
      { action: 'linkClick' }
    >
  | EventType<EventName.HelpClickLibraryLearnMore, { action: 'linkClick' }>
  | EventType<EventName.HelpClickLibraryReplacements, { action: 'linkClick' }>
  | EventType<
      EventName.HelpClickLibraryRespellingGuide,
      { action: 'linkClick' }
    >
  | EventType<
      EventName.HelpClickLibraryRespellingToggle,
      { action: 'linkClick' }
    >
  | EventType<
      EventName.ClipSave,
      {
        clipId: string;
        projectId: string;
        speakerId: string;
        speakerVariantId: string;
        ttsVersion: string;
      }
    >
  | EventType<
      EventName.ReplacementsDictionaryShown,
      {
        location: ReplacementsDictionaryLocation;
        input: string;
      }
    >
  | EventType<
      EventName.SelfServeTeamAdjustSeatCount,
      {
        teamId: string;
        from: number;
        to: number;
      }
    >
  | EventType<
      EventName.SelfServeTeamAbandonUpgrade,
      {
        currentPlan: string;
        location: string;
      }
    >
  | EventType<
      EventName.TeamRemoveMember,
      {
        teamId: string;
        selfServe: boolean;
      }
    >
  | EventType<
      EventName.TutorialChipSelected,
      {
        tutorialCategory: string;
      }
    >
  | EventType<
      EventName.VoiceSearch,
      {
        searchTerm: string;
        tab: string;
        filters?: string[];
      }
    >
  | EventType<
      EventName.VoiceFilterSelected,
      {
        category: string;
        filter: string;
      }
    >
  | EventType<
      EventName.VoiceTabSelected,
      {
        tab: string;
        teamRole?: TeamRole | null;
      }
    >
  | EventType<
      EventName.ClearAllCues,
      {
        projectId: string;
      }
    >
  | EventType<
      EventName.EditorUndoPressed,
      {
        projectId: string;
      }
    >
  | EventType<
      EventName.EditorRedoPressed,
      {
        projectId: string;
      }
    >
  | EventType<
      EventName.CueSliderUpdated,
      {
        projectId: string;
        actorId: string;
        actorVariantId: string;
        ttsVersion: string;
        cueType: StudioCueType;
        value: number;
      }
    >
  | EventType<
      EventName.ContentTileCTAClicked,
      {
        context: string;
        title: string;
        ctaText: string;
        href: string;
      }
    >
  | EventType<
      EventName.OnboardingModal,
      {
        status: 'completed' | 'error';
        selections?: {
          key: SelectionSetItemId;
          value: string[];
        }[];
      }
    >
  | EventType<
      EventName.CreateFolder,
      {
        driveType?: 'Personal' | 'Team' | 'Custom';
      }
    >;

export enum ApiKeyRequestStatus {
  start = 'start',
  abandon = 'abandon',
  complete = 'complete',
  error = 'error',
}

export enum ReplacementsDictionaryLocation {
  // Studio page
  studio = 'studio',

  // Pronunciation page
  newWord = 'pronunciation - new word',
  newReplacement = 'pronunciation - new replacement',
  replacementSelected = 'pronunciation - replacement selected',
}

export enum TeamAccountManagementSource {
  accountTeam = 'accountTeam',
  accountPlan = 'accountPlan',
  projectList = 'projectList',
}

export type CuesApplied = {
  loudness: boolean;
  pace: boolean;
  pause: boolean;
  pitch: boolean;
};

type BaseClipRenderEvent = {
  actorID: string;
  actorVariantID: string;
  actorVariantType: string;
  actorName: string;
  source: string;
  textLength: number;
  ttsVersion: string;
  timeUntilHeadersReceived?: number;
  timeUntilRequestComplete?: number;
  success: boolean;
  renderMode: Editor.StoreState['renderMode'];
  error?: string;
  cues?: CuesApplied;
};

export enum ClipEventRealtime {
  Deleted = EventName.ClipDeleted,
  Render = EventName.ClipRender,
  Reorder = EventName.ClipReorder,
  Renamed = EventName.ClipRenamed,
  MovedProject = EventName.ClipMovedProject,
  Restored = EventName.ClipRestored,
}

export enum ProjectSharedEventType {
  Anyone = 'anyone',
  TeamMember = 'teamMember',
}
